import { getContext, setContext } from 'svelte';
import { writable, type Writable } from 'svelte/store';
import type { UserSessionSettings } from '../../app';

export function create_user_session_store(user_account_uuid?: string, 
                                          user_settings?: UserSessionSettings) {

	const user_session_store = writable({user_account_uuid, user_settings});
	setContext('context_user_session', user_session_store);
}

export function user_session() {
	return getContext<Writable<{user_account_uuid?: string, user_settings?: UserSessionSettings}>>('context_user_session');
}